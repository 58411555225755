export const index_name = process.env.GATSBY_ALGOLIA_INDEX_NAME;

export const currency = process.env.GATSBY_CURRENCY;
export const country = "West Sussex";
export const defaultArea = process.env.GATSBY_DEFAULT_AREA ? process.env.GATSBY_DEFAULT_AREA : "London";
export const defaultArea_Slug = defaultArea.toLowerCase().replace(/ /g,'-');

export const defaultArea_Newhomes = process.env.GATSBY_DEFAULT_AREA_NEWHOMES ? process.env.GATSBY_DEFAULT_AREA_NEWHOMES : "London";
export const defaultArea_Newhomes_Slug = defaultArea_Newhomes.toLowerCase().replace(/ /g,'-');

export const site_name = process.env.GATSBY_SITE_NAME;
export const hasMyaccount = (process.env.GATSBY_MYACCOUNT || null) === '1' ? true : false;

export const IsAllAgentReview = true;
export const postCodeAddressAPI = "ncZgV7m79UipxWpivISlAw37900";

export const footerVatDetails = "Michael Jones and Co. Limited is registered in England & Wales<br />Registered office address: 70 St. Mary Axe, London, EC3A 8BE<br />Registered company number: 03627931. VAT Number: 665153040<br />Part of Lomond";


export const Mail_Vars = {
    fb_link: "https://www.facebook.com/MichaelJonesEA",
    tw_link: "https://twitter.com/michaeljonesea",
    company_phone: "01903 213 281",
    available_time: "We're available Monday to Friday: 8.45am to 6pm, Saturday: 9am to 4pm",
    address: "22-26 Chapel Road, Worthing, West Sussex, BN11 1BE"
}

// export const PageLinks = {
//     about: "about-johnshepherd",
//     general_enquiry: "general-enquiry",
//     news_insight: `about-johnshepherd/insights`,
//     branch_finder: "about-johnshepherd/branch-finder",
//     area_cover: "about-johnshepherd/areas-we-cover",
//     branch_contact: "contact-us",
//     team_contact: "contact-us",
//     teams: "about-johnshepherd/meet-the-team",
//     request_valuation: "property-valuation",
//     privacy_policy: "privacy-policy",
//     book_a_viewing: "book-a-viewing",
//     book_a_valuation: "property-valuation/home-visit-valuation",
//     send_to_friend: "send-to-friend",
//     property_to_rent: "property/to-rent",
//     property_for_sale: "property/for-sale",
//     reviews: "about-johnshepherd/customer-reviews",
//     property_management: "landlords/property-management",
//     financial_services: `financial-services`
// }

export const PropertySearchURL = {
    residential: {
        sales:"/property/for-sale/",
        lettings:"/property/to-rent/",
        newhomes: "/new-homes/for-sale/",
        newdevelopments: "/new-developments/for-sale/"
    },
    commercial: {
        sales:"/commercial-property/for-sale/",
        lettings:"/commercial-property/to-rent/",
    },
    auction: {
        sales:"/auctions/for-sale/"
    }
}

export const PropertyDetailsURL = {
    residential: {
        sales:"/property-for-sale/",
        lettings:"/property-to-rent/",
        newhomes:"/new-homes-for-sale/",
        newdevelopments:"/new-developments-for-sale/"
    },
    commercial: {
        sales:"/commercial-property-for-sale/",
        lettings:"/commercial-property-to-rent/",
    },
    auction: {
        sales:"/auction-for-sale/"
    }
}

export const SearchTitle = {
    residential: {
        sales:"Properties for sale",
        lettings:"Properties to rent",
        newhomes:"New homes",
        newdevelopments:"New developments"
    },
    commercial: {
        sales:"Commercial properties for sale",
        lettings:"Commercial properties to rent",
    },
    auction: {
        sales:"Auction properties for sale"
    }
}

export const PopularSearch_Areas = [ 
    { value: "west-sussex", label: "West Sussex" },
    { value: "worthing", label: "Worthing" },
    { value: "rustington", label: "Rustington" },
    { value: "lancing", label: "Lancing" }   
]

export const PopularSearch_Types = [ 
    { value: "house", label: "Houses" },
    { value: "apartment", label: "Apartments" },
    { value: "bungalow", label: "Bungalows" },
    { value: "maisonette", label: "Maisonettes" }   
]

export const sortByList = [ 
    { value: "Newest", label: "Newest" },
    { value: "Oldest", label: "Oldest" },
    { value: "HighestPrice", label: "Highest Price" },
    { value: "LowestPrice", label: "Lowest Price" }   
]

export const SearchType = {
    sales:"Buy",
    lettings:"Rent",
    newhomes:"New homes"
}

export const FurnishedType = [    
    { value: 'furnished', label: 'Furnished' },
    { value: 'unfurnished', label: 'Unfurnished' },
    // { value: 'part-furnished', label: 'Part furnished' },
]

export const leadooPageSlot = {
    sell_property:"Sellers",
    landlords:"Landlords",
    sales:"Sale listings",
    lettings:"Rental listing",
    branch_finder:"Branch finder",
    general_enquiry:"General enquiry",
    valuation:"Valuation",
    home:"Valuation"
}